@font-face {
  font-display: optional;
  font-family: 'Roboto Condensed';
  src: url('assets/fonts/RobotoCondensed-Regular.ttf');
}

@font-face {
  font-display: optional;
  font-family: 'Myriad Pro';
  src: url('assets/fonts/MYRIADPRO-REGULAR.OTF');
}

@font-face {
  font-display: optional;
  font-family: 'Traditional Arabic';
  src: url('assets/fonts/trado.ttf');
}

.App {
  text-align: center;
}

.App-logo {
  animation: App-logo-spin infinite 20s linear;
  height: 40vmin;
}

.App-header {
  background-color: #282c34;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

@media (min-width: 1440px){
    .child-wrapper {
      width: 1400px;
  }
}

.scrollup {
  z-index: 1000;
  background-color: #c1272d !important;
  fill: #fff !important;
  border-radius: 5px;
}

.scrollup:focus{
  outline: none;
}

:root {
  font-size: 13px;
}

html {
  overflow: auto;
}

html::-webkit-scrollbar-track {
  -webkit-box-shadow: none;
  background-color: transparent;
  border-radius: 25px;
}

html::-webkit-scrollbar {
  width: 6px;
  height: 6px;
  background-color: transparent;
  border-radius: 25px;
}

html::-webkit-scrollbar-thumb {
  background-color: rgb(193 39 45);
  border-radius: 25px;
}

body {
  padding-right: 0;
}
