.header-logo {
    position: absolute;
    left: 20px;
    top: 40px;
}

.header-banner {
    width: 100%;
}

.top-bar, #nav-holder{
    background: black;
}

.top-nav {
    padding: 0;
    margin: 0 20px 0 0;
    list-style: inherit;
    text-align: right;
}

.top-nav li {
    display: inline-block;
    padding-left: 4px;
}

.top-nav a {
    color: #cac5c5;
    font-size: 12px;
    margin: 0 8px;
    line-height: 33px;
    text-transform: uppercase;
    text-decoration: none;
}

.top-nav a:hover {
    color: white;
    cursor: pointer;
}

#navmenu-v a {
    color: white;
}

#navmenu-v > li {
    border-right: 1px solid rgba(255, 255, 255, 0.2);
}

#header #nav-holder #navbar {
    float: left;
    padding-left: 0;
}

#header #nav-holder .nav li a,
#header #nav-holder .nav li div span {
    display: block;
    font-size: 13px;
    text-transform: uppercase;
    color: #fafafa;
    height: 42px;
    min-height: fit-content;
    line-height: 42px;
    padding: 0 15px;
    background: none;
    border: none;
    cursor: pointer;
    font-weight: 800;
}

#header #nav-holder #navbar .nav li ul {
    position: absolute;
    padding: 0;
    list-style: none;
    background: black;
    z-index: 99;
    opacity: 1;
    transition: 0.35s;
    white-space: nowrap;
}

#header #nav-holder #navbar .nav li ul li {
    border-bottom: 1px solid rgba(255, 255, 255, 0.2);
    text-transform: none;
}

#header #nav-holder #navbar .nav li ul li a,
#header #nav-holder #navbar .nav li ul li div span {
    display: block;
    padding: 0 10px;
    font-size: 14px;
    text-decoration: none;
    text-transform: capitalize;
    border-right: none;
    text-align: center;
    font-weight: 400;
    padding-left: 30px;
    padding-right: 30px;
}

.navbar-nav li:hover > div, .navbar-nav li:hover > a {
    background-color: rgb(193, 39, 45);
}

.navbar-nav li ul li:hover > div, .navbar-nav li ul li a:hover div,
.navbar-nav li ul li:hover > div, .navbar-nav li ul li div:hover div, .navbar-nav li ul li:hover > div, .navbar-nav li ul li div:active div, .navbar-nav li ul li:hover > div, .navbar-nav li ul li a:active div  {
    background-image: url('../images/playicon.png');
    background-position: 5% 50%;
    background-size: 6%;
    background-repeat: no-repeat;
    cursor: pointer;
    background-color: transparent;
    color: rgb(193, 39, 45);
}

#header #nav-holder #navbar .nav li ul li div:hover span {
    color: rgb(193, 39, 45);
}

.sub-category-2 ul  {
    left: 100%;
    margin-top: -3em;
}

.sub-category-2 ul li a, .sub-category-2 ul li div span {
    display: block;
    padding: 0 10px;
    font-size: 14px;
    text-decoration: none;
    text-transform: capitalize;
    border-right: none;
    text-align: center;
    font-weight: 400;
}

#header #nav-holder #navbar .nav li ul li ul li:hover div, #header #nav-holder #navbar .nav li ul li ul li:active div {
    background-image: url('../images/pointToRight.png');
    background-position: 5% 50%;
    background-size: 10%;
    background-repeat: no-repeat;
    cursor: pointer;
}

#header #nav-holder .nav li div span.ask-question {
    color: #9E7E01;
}

#header #nav-holder .nav li div span.ask-question:hover {
    background-color: #d3d3d3;
}

.icon {
    line-height: 10px;
    color: #FFFFFF;
    border-radius: 50%;
}

.icon-link {
    line-height: 10px;
    font-size: 18px;
    color: #FFFFFF;
    border-radius: 50%;
}

.fa {
    display: inline-block;
    font: normal normal normal 14px/1 FontAwesome;
    font-size: inherit;
    text-rendering: auto;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    border-radius: 50%;
    margin-top: 5px;
}

.fa-twitter:before {
    content: "\f099";
}

.fa-twitter:hover {
    color: #008EF4;
}

.fa-youtube-play:before {
    content: "\f16a";
}

.fa-youtube-play:hover {
    color: #c4302b;
}

.fa-whatsapp:before {
    content: "\f232";
}

.fa-whatsapp:hover {
    color: #00FC7C;
}

.date {
    color: #cac5c5;
    line-height: 33px;
}

.spacing {
    width: 50px;
}

.footer-icon-link {
    line-height: 10px;
    color: #FFFFFF;
    border-radius: 50%;
    font-size: 35px;
    padding: inherit;
}

.social-area { float: left; }

.social-nav {
    margin: 0;
    padding: 0;
    list-style: none;
    margin-top: 2px;
}

.social-nav li {
    display: inline-block;
    margin: 0 8px;
}

.date-block {
    float: left;
    margin-left: 5%;
}

body { min-width: 320px; }

.navbar-toggle {
    border-color: #fff;
    margin: 3px 0 0 8px;
}

.navbar-toggle .icon-bar { background: #fff; }

@media screen and (max-width: 1600px) {

    .header-logo, .subscription-header-logo {
        top: 45px;
        width: 170px;
    }
}

@media screen and (max-width: 1400px) {

    .header-logo, .subscription-header-logo {
        width: 150px;
    }
}

@media screen and (max-width: 1199px) {

    /* Top Nav */
    .social-nav {
        text-align: right;
        margin-right: 77px;
    }
    .date-area,
    .top-nav {
        text-align: center;
    }

    /* Main Navbar */
    #header #nav-holder .search-holder input {
        width: 250px;
    }

    #logo-holder {
        position: relative;
    }

    .header-logo, .subscription-header-logo {
        top: 10px;
        width: 130px;
    }
}

@media screen and (max-width: 1059px) {

    .header-logo, .subscription-header-logo {
        width: 110px;
    }
}

@media screen and (max-width: 991px) {

    /* Top Nav */
    .social-nav {
        text-align: center;
        margin-right: 0;
    }

    /* Main Navbar */
    #header #nav-holder .search-holder input {
        width: 180px;
    }

    .header-logo, .subscription-header-logo {
        width: 95px;
    }
}

@media screen and (max-width: 800px) {

    .header-logo, .subscription-header-logo {
        width: 75px;
    }
}

@media screen and (max-width: 767px) {

    #header #nav-holder #navbar {
        float: none;
        width: 100%;
        top: 100%;
        left: 0;
        position: absolute;
        background: #000;
        z-index: 99;
        padding-right: 0;
    }

    #navmenu-v > li.main-nav-category a span {
        font-size: 13px;
    }

    .navbar-nav li ul li:hover > div, .navbar-nav li ul li a:hover div,
    .navbar-nav li ul li:hover > div, .navbar-nav li ul li div:hover div, .navbar-nav li ul li:hover > div, 
    .navbar-nav li ul li div:active div, .navbar-nav li ul li:hover > div, .navbar-nav li ul li a:active div  {
        background-position: 40% 50%;
        background-size: 20px;
    }

    #header #nav-holder #navbar .nav li ul li ul li:hover div, 
    #header #nav-holder #navbar .nav li ul li ul li:active div {
        background-position: 40% 50%;
        background-size: 20px;
    }

    .col-md-12:after {
        content: '';
        display: block;
        clear: both;
    }

    #header #nav-holder #navbar .nav li ul {
        position: relative;
    }

    .sub-category-2 ul {
        display: block;
        left: 0;
        margin-top: 0;
    }

    .navbar-nav ul {
        margin-left: 25px;
        border-left: 3px solid rgba(255, 255, 255, 0.2);
    }

    .navbar-nav ul ul {
        margin: 0 0 10px 25px;
    }

    .navbar-nav { margin: 0; }

    /* Main Navbar */
    .search-holder.pull-right {
        float: left !important;
    }

    #header #nav-holder .search-holder input {
        width: 350px;
    }

    #header #nav-holder .search-holder input, #header #nav-holder .search-holder button {
        height: 44px;
    }

    .navbar-toggle {
        padding: 12.8px 10px;
        margin: 2px 15px 0 8px;
    }
}

@media screen and (max-width: 650px) {

    .header-logo, .subscription-header-logo {
        width: 60px;
        left: 8px;
        top: 5px;
    }
}

@media screen and (max-width: 550px) {

    /* Top Nav */
    .social-nav {
        margin-top: 0;
        margin-bottom: 5px;
    }

    .top-nav {
        margin: 0 0 5px 0;
    }

    .top-nav li {
        padding-left: 0;
    }

    .top-nav a {
        font-size: 11px;
        margin: 0 7px;
        line-height: 26px;
    }

    .date {
        line-height: 1.8;
        font-size: 12px;
    }

    .date-area,
    .social-area {
        width: 100%;
        text-align: center;
    }

    /* Main Navbar */
    #header #nav-holder .search-holder input {
        width: 200px;
    }
    .navbar-toggle {
        margin: 2px 2px 0 8px;
    }

    #header #nav-holder #navbar .nav li ul li ul li:hover div, #header #nav-holder #navbar .nav li ul li ul li:active div {
        background-position: 5% 50%;
        background-size: 20px;
    }

    .navbar-nav li ul li:hover > div, .navbar-nav li ul li a:hover div,
    .navbar-nav li ul li:hover > div, .navbar-nav li ul li div:hover div, .navbar-nav li ul li:hover > div, 
    .navbar-nav li ul li div:active div, .navbar-nav li ul li:hover > div, .navbar-nav li ul li a:active div  {
        background-position: 5% 50%;
    }
}

@media screen and (max-width: 500px) {

    .header-logo, .subscription-header-logo {
        width: 45px;
        top: 6px;
    }
}

@media screen and (max-width: 375px) {

    /* Main Navbar */
    .navbar-toggle {
        padding: 10.3px 10px;
    }
    #header #nav-holder .search-holder input, #header #nav-holder .search-holder button {
        height: 40px;
    }
    #header #nav-holder .search-holder input {
        width: 180px;
    }
    .header-logo, .subscription-header-logo {
        width: 35px;
        top: 5px;
    }
}
