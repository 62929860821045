#SearchFormKeyword {
    border: 0px !important;
    background: none !important;
    margin: 0px !important;
}

#header #nav-holder .search-holder input {
    width: 300px;
    padding: 5px;
    border: 1px solid #f2f2f2;
    outline: none;

}

#header #nav-holder .search-holder button {
    width: 42px;
    background: #c1272d;
    color: white;
    border: 0;
    position: relative;
    left: -3px;
    outline: none;
    padding: 1px 6px;
}

#header #nav-holder .search-holder input, #header #nav-holder .search-holder button {
    height: 42px;
    box-sizing: border-box;
    vertical-align: top;
}

@media screen and (max-width: 500px) {
    #header #nav-holder .search-holder input { width: 195px; }
}