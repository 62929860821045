.custom-container {
    width: 700px;
    margin: 0 auto;
    position: relative;
}

.full-page-container {
    padding: 0;
}

.custom-column {
    padding: 0;
}

.submit.button {
    background-color: #C90D05;
    padding: 7px 30px;
    font-size: 16px;
    height: auto !important;
    border-radius: 5px;
    color: white;
    border: 1px solid #C90D05;
}

.submit.button:hover, .submit:focus {
    background-color: white;
    color: #C90D05 !important;
    border: 1px solid #C90D05;
    transition: all 0.2s ease-in-out;
    box-shadow: none;
    outline: none;
}

.reset.button {
    background-color: white;
    color: #c32e34 !important;
    border: 1px solid #c32e34;
    padding: 7px 30px;
    font-size: 16px;
    height: auto !important;
    border-radius: 5px;
    margin-left: 20px;
}

.reset.button:hover, .reset.button:focus {
    box-shadow: none;
    background-color: #c32e34;
    color: white !important;
    outline: none;
}

.button[disabled], .button[disabled]:hover {
    background-color: #c7c7c7;
    color: #fff !important;
    border: 1px solid #c7c7c7;
}

input[type=checkbox] {
    margin-right: 10px;
    transform: scale(1.1);
    position: relative;
    top: -1px;
}

.success-message {
    border: 3px solid #ff9b00;
    background: #ffcc80;
    color: #529214;
    padding: 10px;
    font-weight: bold;
    margin: 15px 0;
    font-size: 18px;
}

.flashMessage {
    border: 3px solid rgb(195 230 203);;
    background: rgb(212 237 218);
    padding: 10px 5px;
    font-weight: bold;
    margin: 15px 0;
    color: rgb(21 87 36);
    font-size: 14px;
}

span[dir="rtl"],
h1[dir="rtl"], h2[dir="rtl"], h3[dir="rtl"],
h4[dir="rtl"], h5[dir="rtl"], h6[dir="rtl"],
p[dir="rtl"] span, p[dir="rtl"], p[dir="rtl"] strong, p[dir="rtl"],
span[dir="rtl"], strong[dir="rtl"], div[dir="rtl"] {
    font-family: "Traditional Arabic", sans-serif;
    font-weight: 700;
}

.authMessage {
    border: 2px solid #FFD324;
    background: #FFF6BF;
    color: #817134;
    padding: 10px;
    margin: 15px 0;
    font-size: 18px;
}

.error-message {
    border: 3px solid #ff9b00;
    background: #ffcc80;
    color: #D12F19;
    padding: 10px;
    font-weight: bold;
    margin: 15px 0;
    font-size: 18px;
}

.auth-message {
    border: 2px solid #FFD324;
    background: #FFF6BF;
    color: #817134;
    padding: 10px;
    margin: 15px 0;
    font-size: 18px;
}

.center {
    text-align: center;
}

.flex-error {
    display: block;
    color: #D12F19;
    font-weight: bold;
}

.loader {
    margin-top: 30%;
    margin-bottom: 30%;
    text-align: center;
}

.inline-loader-container {
    display: flex;
}

.inline-loader {
    text-align: center;
    height: auto;
    position: relative;
    margin-left: 20px;
}

@media screen and (max-width: 767px) {
    .custom-col-6 {
        width: 50%;
        float: left;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: flex-start;
    }
}

@media screen and (max-width: 450px) {
    .custom-col-6 {
        width: 100%;
    }
}
