#banner {
    position: relative;
}

.flex-slider {
    margin: 0;
    position: relative;
    zoom: 1;
    padding: 0;
}

#slider {
    overflow: hidden;
    height: calc(100vh - 400px);
}

.slides {
    height: 100%;
    display: block;
    zoom: 1;
    margin: 0;
    padding: 0;
    list-style: none;
}

.slides li {
    height: 100%;
    width: 100%;
    float: left;
    margin-right: -100%;
    position: relative;
    opacity: 1;
    display: block;
    z-index: 2;
    padding: 0;
    list-style: none;
}

.announcement-title {
    position: absolute;
    z-index: 99;
    left: 0;
    right: 0;
    max-width: 1140px;
    margin: auto;
    color: white;
    top: 40%;
    transform: translateY(-50%);
    font-family: 'Roboto Condensed', sans-serif;
    text-transform: uppercase;
    font-size: 48px;
    font-weight: 300;
}

#announcement-banner {
    width: 100%;
    height: 100%;
    object-fit: cover;
    display: block;
}

.flex-direction-nav {
    height: 100%;
    margin: 0;
    padding: 0;
    list-style: none;
}

.flex-nav {
    height: 100%;
}

.flex-direction-nav .flex-disabled {
    opacity: 0!important;
    filter: alpha(opacity=0);
    cursor: default;
    z-index: -1;
}

.flex-direction-nav #flex-prev {
    left: 0px;
    border-right: 3px solid #c19901;
}

.flex-direction-nav #flex-prev:before {
    content: "\f104";
    font-family: 'FontAwesome';
    font-size: 24px;
    display: inline-block;
    width: 40px;
    text-align: center;
    line-height: 36px;
    color: white;
}

.flex-direction-nav #flex-next {
    right: 0px;
    border-left: 3px solid #c19901;
    text-align: right;
}

.flex-direction-nav #flex-next:before {
    content: "\f105";
    font-family: "FontAwesome";
    font-size: 24px;
    display: inline-block;
    width: 40px;
    text-align: center;
    line-height: 36px;
    color: white;
}

.flex-direction-nav span {
    color: #FFFFFF;
    text-decoration: none;
    display: block;
    width: 40px;
    height: 40px;
    margin: -20px 0 0;
    position: absolute;
    top: 50%;
    z-index: 10;
    overflow: hidden;
    cursor: pointer;
    text-shadow: 1px 1px 0 rgba(255, 255, 255, 0.3);
    transition: all 0.3s ease-in-out;
    background: rgba(0,0,0,0.8);
}

#carousel {
    max-width: 1140px;
    margin: auto;
    position: absolute;
    bottom: -20px;
    left: 0;
    right: 0;
    z-index: 98;
}

.flex-viewport {
    max-height: 2000px;
    transition: all 1s ease;
    overflow: hidden;
    position: relative
}

#flex-slides {
    width: 800%;
    transition-duration: 0.6s;
    transform: translate3d(0px, 0px, 0px);
    display: block;
    zoom: 1;
    margin: 0;
    padding: 0;
    list-style: none;
}

#flex-slides li {
    width: 270px;
    margin-right: 15px;
    margin-bottom: 20px;
    display: inline-block !important;
    vertical-align: bottom;
    float: none !important;
    padding: 0;
    list-style: none;
}

#flex-slides .thumb {
    background: rgba(42, 42, 42, 0.5);
    border-top: 2px solid #c19901;
    opacity: .8;
    transition: 0.35s;
}

#flex-slides .flex-active .thumb {
    padding-bottom: 40px;
    background: rgba(42, 42, 42, 0.9);
    opacity: 1;
}

#flex-slides .flex-active .thumb .content a {
    color: #c19901;
    font-size: 18px;
    font-weight: 700;
    text-transform: uppercase;
}

#flex-slides .flex-active .thumb .date {
    font-weight: 400;
    text-transform: uppercase;
}

#flex-slides .heading {
    background: black;
    color: white;
    padding: 13px;
    display: inline-block;
    transition: color 0.35s;
    font-size: 11px;
    text-transform: uppercase;
}

#flex-slides .flex-active .heading {
    color: #c19901;
}

#flex-slides .content {
    padding: 13px;
    font-size: 14px;
    opacity: 0.6;
    color: white;
}

#flex-slides .flex-active .content {
    opacity: 1;
}

#flex-slides .content a {
    color: #FFFFFF;
}

#flex-slides .date {
    color: white;
    opacity: 0.6;
    padding: 0 13px 13px;
    font-family: 'Roboto Condensed', sans-serif;
    font-size: 12px;
    position: relative;
}

@media screen and (max-width: 1199px) {

    #carousel {
        padding: 0 20px;
        max-width: 1000px;
    }

    #flex-slides li { width: 229px; }
}

@media screen and (max-width: 1000px) {

    #carousel { max-width: 768px; }

    #flex-slides li { width: 171px; }
}

@media screen and (max-width: 767px) {
    #carousel {
        position: relative;
    }

    #slider {
        display: none;
    }

    #flex-slides {
        width: auto;
        text-align: center;
    }

    #flex-slides li {
        width: calc( 50% - 20px );
        margin: 0 10px 20px;
        vertical-align: top;
        text-align: left;
    }

    #flex-slides .flex-active .thumb {
        padding-bottom: 0;
        background: #fff;
        opacity: 1;
        box-shadow: 0 10px 20px #ddd;
    }
    #flex-slides .thumb {
        background: #fff;
        border: 1px solid #ddd;
        border-top: 2px solid #c19901;
    }

    #flex-slides .heading,
    #flex-slides .content a,
    #flex-slides .date { color: #333; }

}

@media screen and (max-width: 550px) {

    .flex-viewport { overflow: visible; }

    #flex-slides li {
        width: 100%;
        margin: 0 0 20px;
        display: block;
    }
}
