.subscription-header-logo {
    position: absolute;
    left: 20px;
    top: 5px;
}

.subscription-form {
    padding: 300px 0;
    background-image: linear-gradient(to right, rgba(8, 17, 7, 0.4), rgba(171, 136, 1, 0.6)), url('../images/thankyou.jpg');;
    background-repeat: no-repeat;
    background-position: center center;
    background-attachment: scroll;
    background-size: cover;
}

.thank-you {
    margin: 0 auto;
    max-width: 1299px;
}

.subscription-label {
    margin: 10px 0 20px;
    color: white;
    font-size: 80px;
    text-align: center;
}

.description {
    font-size: 35px;
    color: white;
    text-align: center;
}

.subscription-buttons {
    margin-top: 20px;
}

.input-field label {
    width: 400px;
    font-weight: normal;
}

@media screen and (max-width: 1440px) {
    .thank-you {
        max-width: 991px;
    }

    .description {
        font-size: 27px;
    }
}

@media screen and (max-width: 1059px) {
    .subscription-form {
        padding: 200px 0;
    }

    .thank-you {
        max-width: 650px;
    }

    .subscription-label {
        font-size: 55px;
    }

    .description {
        font-size: 22px;
    }
}

@media screen and (max-width: 700px) {
    .thank-you {
        width: auto;
        padding: 0 25px 0;
    }

    .subscription-label {
        font-size: 45px;
    }

    .description {
        font-size: 16px;
    }
}

@media screen and (max-width: 479px) {
    .subscription-label {
        font-size: 32px;
    }

    .description {
        font-size: 14px;
    }
}
