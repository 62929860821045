#errorPageContainer {
    position: relative;
    height: 100vh;
    background-color: #f1f1f1;
}

.errorPage {
    position: absolute;
    left: 50%;
    top: 50%;
    -webkit-transform: translate(-50%,-50%);
    -ms-transform: translate(-50%,-50%);
    transform: translate(-50%,-50%);
    width: 100%;
    text-align: center;
    line-height: 1.4;
}

.errorPage h2 {
    font-size: 33px;
    font-weight: 700;
    text-transform: uppercase;
    margin: 20px 0;
}

.errorPage p {
    font-family: cabin,sans-serif;
    font-size: 16px;
    color: #000;
    font-weight: 400;
}

.errorPage .reloadLink {
    color: #337ab7;
    text-decoration: none;
    border: none;
    padding: 0;
}
