#news-ticker {
    font-family: 'Roboto Condensed', sans-serif;
}

.latest-updates {
    display: flex;
    align-items: center;
}

.news-title {
    background: #c1272d;
    color: white;
    font-weight: bold;
    display: inline-block;
    padding: 15px 15px;
    text-transform: uppercase;
    font-size: 16px;
    vertical-align: bottom;
}

.news-scroller {
    display: inline-block;
    overflow: hidden;
    font-size: 14px;
    vertical-align: middle;
    padding: 0 20px;
}

.news-scroller > div {
    display: block;
}

.news-scroller a {
    color: #000;
    text-transform: uppercase;
}

@media screen and (max-width: 991px) {
    .news-title {
        font-size: 14px;
        padding: 10px 8px;
    }
}

@media screen and (max-width: 767px) {
    #news-ticker {
        padding: 0 15px;
    }

    .news-scroller > div {
        font-size: 12px;
    }
}

@media screen and (max-width: 475px) {
    .news-title {
        font-size: 12px;
        padding: 10px 5px;
    }
}
