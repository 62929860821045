
.footer-logo {
    background: #2d2d2d;
    padding: 10px 0;
}

.footer-text {
    padding: 5px;
    color: #c19901;
    font-size: 16px;
}

.footer-logo-image {
    border-radius: 50%;
    width: 50px;
    height: 50px;
    background-color: #fff;
}

.footer-links {
    background: black;
    color: white;
    padding-top: 30px;
}

.copy-rights {
    margin-top: 10px;
    color: #cac5c5;
}

#footer {
    font-family: 'Roboto', sans-serif;
    position: absolute;
    width: 100%;
}

.footer-links h2 {
    font-family: 'Roboto Condensed', sans-serif;
    font-size: 24px;
    margin: 0;
    font-weight: 300;
}

.bottom-links {
    margin: 20px 0 0;
    padding: 0 0 30px;
    list-style: none;
}

.bottom-links a {
    line-height: 24px;
    color: #cac5c5;
    text-decoration: none;
}

.bottom-links a:hover {
    color: #fff;
}

#parallax {
    background-image: url(../images/pattern.png);
    height: 350px;
    margin: 30px auto 0;
    background-attachment: fixed;
    position: relative;
}

#parallax .content {
    position: absolute;
    top: 50%;
    left: 50%;
    -webkit-transform: translate(-50%, -50%);
    -moz-transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);
    -o-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
    font-family: 'Myriad Pro', sans-serif;
    color: white;
    font-size: 36px;
    text-align: center;
    font-weight: 300;
    max-width: 1000px;
}

#parallax .content .reference {
    font-size: 24px;
    display: block;
    margin-top: 20px;
}

#footer .donate-link {
    outline: none;
}

.footer-logo-links {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 10px;
}

.footer-logo-links .footer-icon-link {
    padding: 0 15px;
}

a.twitter-link:hover {
    color: #008EF4;
}

a.youtube-play-link:hover {
	color: #c4302b;
}

a.whatsapp-link:hover {
    color: #00FC7C;
}


@media screen and (max-width: 1024px) {

    #parallax .content {
        font-size: 17px;
    }

    .side-bar-image {
        display: block;
        margin: 0 auto;
    }
}

@media screen and (max-width: 767px) {

    #parallax .content {
        font-size: 16px;
    }
}

@media screen and (max-width: 500px) {

    #parallax .content {
        font-size: 12px;
    }

    #footer {
        min-width: 320px;
    }
}

@media screen and (max-width: 375px) {

    .footer-logo-links .footer-icon-link {
        padding: 0 10px;
        font-size: 26px;
    }
}
