.result-not-found {
    padding-top: 20px;
    padding-bottom: 30px;
}

.category-heading {
    text-transform: capitalize;
    font-size: 33px;
    color: #000;
    font-weight: 600;
    margin: 10px 0 40px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.page-no {
    font-size: 20px;
    color: #5f431b;
    vertical-align: bottom;
    text-align: right;
}

.fatwa-image {
    max-width: 100%;
    height: auto;
}

.image-holder {
    width: 56px;
    height: 56px;
    -webkit-border-radius: 50px;
    -moz-border-radius: 50px;
    border-radius: 50px;
    position: absolute;
    overflow: hidden;
    box-sizing: border-box;
    border: 2px solid #000;
    background: #fff;
    text-align: center;
    top: -8px;
    left: -16px;
    display: flex;
}

.logo-image {
    width: 50px;
    height: 50px;
}

.panel-header {
    color: white;
    font-family: 'Helvetica Neue', sans-serif;
    font-size: 16px;
    line-height: 39px;
    text-transform: uppercase;
    box-sizing: border-box;
    position: relative;
    padding-left: 50px;
    background: #000;
}

.questions {
    margin: 0;
    padding: 0;
    list-style: none;
    background: #f2f2f2;
}

.question:before {
    content: '';
    position: absolute;
    width: 7px;
    top: 8px;
    bottom: 8px;
    left: 22px;
    background: black;
}

.question {
    padding: 14px 14px 14px 50px;
    font-size: 16px;
    position: relative;
    border-bottom: 1px solid white;
}

.question_link, .question_link:hover, .question_link:visited {
    color: #000;
    text-decoration: none;
    font-weight: normal;
}

#qa-header {
    clear: both;
    padding: 5px;
    margin: 7px;
}

#qa-header .row {
    border-bottom: 5px solid black;
    display: flex;
    justify-content: space-between;
}

.download-section span {
    color: gray;
    font-size: 14px;
    font-style: italic;
}

.download-section ul {
    margin-top: 20px;
    padding-inline-start: 10px;
    padding-inline-end: 0;
}

.download-section ul li {
    margin-bottom: 20px;
}

.download-section ul li a {
    color: #000;
    font-size: 16px;
    font-weight: 600;
}

@media screen and (max-width: 600px) {
    #qa-header .row {
        display: block;
    }
}

.qa-headers {
    padding: 0px;
}

.qa-header-heading {
    font: bold 12px/14px "Myriad Pro";
    color: #5f431b;
}

.qa-header-content {
    text-transform: capitalize;
    padding-left: 3px;
    font-size: 14px !important;
}

.question-panel, .answer-panel, .result-not-found {
    margin-bottom: 20px;
}

.result-not-found {
    border-bottom: 5px solid black;
    margin-bottom: 20px;
}

.qa-content {
    padding-top: 25px;
    padding-bottom: 5px;
    word-break: break-word;
    font-size: 20px;
}

@media screen and (max-width: 1024px) {
    .qa-content {
        font-size: 16px;
    }
}

.qa-content-title {
    padding-top: 25px;
    padding-bottom: 5px;
    word-break: break-word;
}

.footer-disclaimer {
    font-family: Arial, Helvetica, sans-serif;
    font-size: 10px;
    font-style: italic;
}

.pagination-wrapper {
    text-align: center;
    padding-left: 0;
    margin: 20px 0;
    border-radius: 4px;
    list-style: none;
}

.pagination-wrapper ul.pages {
    padding: 0;
}

.page {
    display: inline-block;
    margin-right: 6px;
    width: 36px;
    height: 36px;
    -webkit-border-radius: 36px;
    -moz-border-radius: 36px;
    border-radius: 36px;
    border: 1px solid #f2f2f2;
    line-height: 34px;
    text-align: center;
    color: #000;
    text-decoration: none;
    transition: .35s;
}

.page-link {
    display: inline-block;
    width: 36px;
    height: 36px;
    border-radius: 36px;
    border: 1px solid #f2f2f2;
    line-height: 34px;
    text-align: center;
    color: #000;
    transition: .35s;
    background-image: none !important;
    padding: 0 !important;
}

.page:not(.disabled) .page-link:hover, .page .page-link.active {
    background: #c19901;
    color: white;
    text-decoration: none;
}

.page.disabled .page-link, .page-link:focus {
    text-decoration: none;
    color: #000;
}

.latest-fatwas-image {
    padding: 0px 5px 10px 0px;
    width: 75px;
}

.hadith {
    padding: 14px 14px 14px 50px;
}

.fatwa-holder {
    font-family: 'Myriad Pro', Helvetica, Arial, sans-serif !important;
    font-size: 20px;
    margin-top: 30px;
}

.fatwa-holder h4 {
    font-family: 'Myriad Pro', Helvetica, Arial, sans-serif !important;
    font-size: 20px;
}

.pg-viewer-wrapper {
    overflow: auto;
}

@media screen and (min-width: 1023px) {
    .sharebuttons {
        width: 40px;
        position: sticky;
        top: 15px;
        left: 8px;
        margin: 0 0 -298px -80px;
        z-index: 100;
    }
}

@media screen and (max-width: 600px) {
    .category-heading {
        font-size: 28px;
    }

    .question {
        padding: 14px 14px 14px 38px;
        font-size: 14px;
    }

    .question:before {
        width: 5px;
    }
    .latest-fatwas-image {
        padding: 0px 5px 10px 0px;
        width: 60px;
    }
}

@media screen and (max-width: 425px) {
    .category-heading {
        font-size: 23px;
    }

    .panel-header {
        font-size: 14px;
        line-height: 35px;
    }

    .image-holder {
        width: 45px;
        height: 45px;
        top: -3px;
        left: -10px;
    }

    .image-holder .logo-image {
        width: 40px;
        height: 40px;
    }

    .latest-fatwas-image {
        padding: 0px 5px 10px 0px;
        width: 55px;
    }
}
